import { useState, useCallback, useEffect, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button, { ButtonScale, ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import { H1Section } from 'bloko/blocks/header';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import TranslateGuard from 'bloko/blocks/translateGuard';
import VSpacing from 'bloko/blocks/vSpacing';
import Metrics from 'bloko/common/metrics';
import throttle from 'bloko/common/throttle';

import { pushRelatedVacanciesAction } from 'lux/models/relatedVacancies';
import { addUserLabelsForVacancies } from 'lux/models/userLabelsForVacancies/userLabels';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';
import VacancySerpBanner from 'src/components/VacancySerpBanner';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import RelatedVacanciesTitle from 'src/components/RelatedVacancies/Title';

const AUTO_LOAD_PAGES = 3;
const SCROLL_THROTTLE_DELAY_MS = 1000;
const SCROLL_BOTTOM_OFFSET = 500;
const BANNER_POSITION = 3;

function isScrolledPastThreshold(currentPageNumber, totalPages, wrapper) {
    const viewportBottom = Metrics.getViewportMetrics().bottom;
    const contentBottom = Metrics.getRelativeMetrics(wrapper).bottom;
    return (
        viewportBottom + SCROLL_BOTTOM_OFFSET >= contentBottom &&
        currentPageNumber < totalPages &&
        currentPageNumber < AUTO_LOAD_PAGES
    );
}

const RelatedVacancies = ({ blockRef, initialType, trls }) => {
    const relatedVacancies = useSelector((state) => state.relatedVacancies);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const searchSessionId = useSelector((state) => state.searchSessionId);
    const bannersBatchUrl = useSelector((state) => state.bannersBatchUrl);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const currentPageNumber = useRef(1);
    const wrapperRef = useRef();
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const loadMoreVacancies = useCallback(() => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        fetcher
            .get('/shards/vacancy/related_vacancies', {
                params: {
                    vacancyId,
                    page: currentPageNumber.current,
                    type: relatedVacancies.type,
                    searchSessionId,
                },
            })
            .then((response) => {
                const actions = response.vacancies.map(({ vacancyId, userLabels }) =>
                    addUserLabelsForVacancies({ vacancyId, labels: userLabels })
                );

                dispatch([...actions, pushRelatedVacanciesAction(response.vacancies)]);
                currentPageNumber.current += 1;
                if (currentPageNumber.current >= relatedVacancies.totalPages) {
                    setShowLoadMore(false);
                } else if (currentPageNumber.current >= AUTO_LOAD_PAGES) {
                    setShowLoadMore(true);
                }
            })
            .catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [addNotification, dispatch, isLoading, relatedVacancies, searchSessionId, vacancyId]);

    useEffect(() => {
        if (!relatedVacancies.resultsFound) {
            return undefined;
        }
        const onScrollHandler = throttle(() => {
            if (isLoading) {
                return;
            }
            if (isScrolledPastThreshold(currentPageNumber.current, relatedVacancies.totalPages, wrapperRef.current)) {
                loadMoreVacancies();
            }
        }, SCROLL_THROTTLE_DELAY_MS);

        document.addEventListener('scroll', onScrollHandler);

        return () => {
            document.removeEventListener('scroll', onScrollHandler);
        };
    }, [isLoading, relatedVacancies, loadMoreVacancies, currentPageNumber, wrapperRef]);

    if (!relatedVacancies.resultsFound) {
        return null;
    }

    const renderBannerIfNeeded = (index) => {
        if (!bannersBatchUrl || index % relatedVacancies.itemsOnPage !== BANNER_POSITION) {
            return null;
        }
        const isOdd = Math.floor((index - BANNER_POSITION) / relatedVacancies.itemsOnPage) % 2 !== 0;
        const bannerNumberOnPage = Math.ceil(index / (BANNER_POSITION * 2));
        return (
            <VacancySerpBanner
                isOdd={isOdd}
                position={index}
                adsPositionInfo={`${bannerNumberOnPage}_${index}`}
                bannerNum={bannerNumberOnPage}
            />
        );
    };

    return (
        <div className="vacancy-section" ref={blockRef} data-qa="vacancy-view-vacancies-from-search">
            <H1Section Element="h2">
                <RelatedVacanciesTitle type={initialType || relatedVacancies.type} />
            </H1Section>
            <VSpacing base={4} />
            <div ref={wrapperRef}>
                {relatedVacancies?.vacancies?.map((vacancy, index) => (
                    <Fragment key={vacancy.vacancyId}>
                        {renderBannerIfNeeded(index)}
                        <VacancySearchItem
                            vacancy={vacancy}
                            vacancySource={Source.RelatedVacancies}
                            hhtmFromLabel={`${relatedVacancies.type}_vacancies`}
                        />
                    </Fragment>
                ))}
            </div>
            {isLoading && (
                <div className="related-vacancies-loading-indicator">
                    <Gap top>
                        <Loading scale={LoadingScale.Small} />
                    </Gap>
                </div>
            )}
            {!isLoading && showLoadMore && (
                <Gap top>
                    <Button
                        scale={ButtonScale.Small}
                        kind={ButtonKind.Secondary}
                        appearance={ButtonAppearance.Outlined}
                        onClick={loadMoreVacancies}
                    >
                        <TranslateGuard useSpan>{trls[RelatedVacancies.trls.loadMoreVacancies]}</TranslateGuard>
                    </Button>
                </Gap>
            )}
        </div>
    );
};

RelatedVacancies.trls = {
    loadMoreVacancies: 'vacancy.related.loadMoreVacancies',
};

RelatedVacancies.propTypes = {
    blockRef: PropTypes.object,
    initialType: PropTypes.string,
    trls: PropTypes.object,
};

export default translation(RelatedVacancies);
